import { useLocation, useNavigate, useParams } from "react-router";
import { React, Fragment, useEffect, useState } from "react";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";
function IncomeSalesRevenue(props) {
  let userData = localStorage.getItem("user_data");
  let userObj = userData
    ? JSON.parse(localStorage.getItem("user_data")).user
    : undefined;
  const operation = useParams().op;
  const params = useParams();
  const location = useLocation();
  const [businessData, setBusinessData] = useState({});
  const [productData, setProductData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  const [productSKUs, setProductSKUs] = useState();
  const [totalSalesRevenueArrayMonths, setTotalSalesRevenueArrayMonths] = useState()
  const [totalSalesRevenueArrayYear, setTotalSalesRevenueArrayYear] = useState()
  const [totalSalesRevenueArrayMonthsGST, setTotalSalesRevenueArrayMonthsGST] = useState()
  const [totalSalesRevenueArrayYearGST, setTotalSalesRevenueArrayYearGST] = useState()
  const [totalVariableArrayMonths, setTotalVariableArrayMonths] = useState()
  const [totalVariableArrayYear, setTotalVariableArrayYear] = useState()
  const [totalVariableArrayMonthsGST, setTotalVariableArrayMonthsGST] = useState()
  const [totalVariableArrayYearGST, setTotalVariableArrayYearGST] = useState()
  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          setIsLoader(false);
          // let productValue;
          // if (data.data[0].manufacturingIds) {
          //   setProductData(data.data[0].manufacturingIds)
          //   productValue = data.data[0].manufacturingIds;
          // } else if (data.data[0].trading) {
          //   setProductData(data.data[0].trading)
          //   productValue = data.data[0].trading;
          // } else if (data.data[0].service) {
          //   setProductData(data.data[0].service)
          //   productValue = data.data[0].service;
          // } else if (data.data[0].animalhusbandry) {
          //   setProductData(data.data[0].animalhusbandry)
          //   productValue = data.data[0].animalhusbandry;
          // } else if (data.data[0].agro) {
          //   setProductData(data.data[0].agro)
          //   productValue = data.data[0].agro;
          // } else {
          // }
          let productValue;
          let businessTypes = [];
          if (data.data[0].manufacturingIds) {
            businessTypes.push(data.data[0].manufacturingIds)
          }
          if (data.data[0].trading) {
            businessTypes.push(data.data[0].trading)
          }
          if (data.data[0].service) {
            businessTypes.push(data.data[0].service)
          }
          if (data.data[0].animalhusbandry) {
            businessTypes.push(data.data[0].animalhusbandry)
          }
          if (data.data[0].agro) {
            businessTypes.push(data.data[0].agro)
          }
          // console.log(businessTypes)
          const combinedValue = {
            orderingFrequency: { fieldOrderingFrequencyName: [] },
            productList: [],
            rawMaterialConsumption: { rawMaterial: [] },
            rawMaterialProcurement: {
              fieldProductRMName: [],
              initialInputCost: 0,
              initialInputCostGST: 0,
              monthlyInputGSTPaid: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              monthlyInputPurchaseB4GST: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              yearlyInputGSTPaid: {
                1: 0,
                2: 0,
                3: 0,
              },
              yearlyInputPurchaseB4GST: {
                1: 0,
                2: 0,
                3: 0,
              }
            },
            salesProjection: {
              fieldProductSkuName: [],
              fieldProductsName: [],
              totalMonthlyCashInflowOfSales: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSales: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyCashInflowOfSalesGST: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSalesGST: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyGSTCollectOnSale: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyGSTCollectOnSale: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlySalesRevenue: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlySalesRevenue: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyValues: {
                details: {
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  4: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  5: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  6: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  7: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  8: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  9: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  10: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  11: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  12: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                },
                aggregate: {
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                }
              },
              creditCardFee: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                },
              },
              creditCardFeeCashInFlow: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                },
              }
            },
          };
          for (let type = 0; type < businessTypes.length; type++) {
            // console.log(type);
            // console.log(businessTypes[type])
            //orderFrequency
            combinedValue.orderingFrequency.fieldOrderingFrequencyName = [
              ...(combinedValue?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
              ...(businessTypes?.[type]?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
            ];
            // console.log(businessTypes[type])
            //productList
            combinedValue.productList = [...(combinedValue?.productList), ...businessTypes[type]?.productList];
            // console.log(businessTypes[type])
            //consumption
            combinedValue.rawMaterialConsumption.rawMaterial = [
              ...(combinedValue?.rawMaterialConsumption?.rawMaterial ?? []),
              ...(businessTypes[type].rawMaterialConsumption?.rawMaterial ?? []),
            ];
            // console.log(businessTypes[type])
            //procurement
            combinedValue.rawMaterialProcurement.fieldProductRMName = [
              ...(combinedValue?.rawMaterialProcurement?.fieldProductRMName ?? []),
              ...(businessTypes[type].rawMaterialProcurement?.fieldProductRMName ?? []),
            ];
            combinedValue.rawMaterialProcurement.initialInputCost += businessTypes[type].rawMaterialProcurement?.initialInputCost ? parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCost) : 0;
            combinedValue.rawMaterialProcurement.initialInputCostGST += businessTypes[type].rawMaterialProcurement?.initialInputCostGST ? parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCostGST) : 0;
            for (let month = 1; month <= 12; month++) {
              combinedValue.rawMaterialProcurement.monthlyInputGSTPaid[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month] ? parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]) : 0;
              combinedValue.rawMaterialProcurement.monthlyInputPurchaseB4GST[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month] ? parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]) : 0;
            }
            // console.log('this')
            for (let year = 1; year <= 3; year++) {
              combinedValue.rawMaterialProcurement.yearlyInputGSTPaid[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year] ? parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]) : 0;
              combinedValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year] ? parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]) : 0;
            }
            // console.log(businessTypes[type])
            //salesProjection
            combinedValue.salesProjection.fieldProductSkuName = [
              ...(combinedValue?.salesProjection?.fieldProductSkuName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductSkuName ?? []),
            ];
            combinedValue.salesProjection.fieldProductsName = [
              ...(combinedValue?.salesProjection?.fieldProductsName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductsName ?? []),
            ];
            for (let month = 1; month <= 12; month++) {
              combinedValue.salesProjection.totalMonthlyCashInflowOfSales[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlyCashInflowOfSalesGST[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlyGSTCollectOnSale[month] += businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlySalesRevenue[month] += businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].commission += businessTypes[type].salesProjection?.totalMonthlyValues?.details?.[month]?.commission ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].commission) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].transport += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales) : 0;
              combinedValue.salesProjection.creditCardFee.details[month] += businessTypes[type].salesProjection?.creditCardFee?.details?.[month] ? parseFloat(businessTypes[type].salesProjection?.creditCardFee.details[month]) : 0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.details[month] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.details?.[month] ? parseFloat(businessTypes[type].salesProjection?.creditCardFeeCashInFlow.details[month]) : 0;
            }
            for (let year = 1; year <= 3; year++) {
              combinedValue.salesProjection.totalYearlyCashInFlowOfSales[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyCashInFlowOfSalesGST[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyGSTCollectOnSale[year] += businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year]) : 0;
              combinedValue.salesProjection.totalYearlySalesRevenue[year] += businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year]) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].commission += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].transport += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales) : 0;
              combinedValue.salesProjection.creditCardFee.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFee?.aggregate?.[year] ? parseFloat(businessTypes[type].salesProjection?.creditCardFee.aggregate[year]) : 0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[year] ? parseFloat(businessTypes[type].salesProjection?.creditCardFeeCashInFlow.aggregate[year]) : 0;
            }
            // console.log(type, combinedValue)
          }
          productValue = combinedValue;
          // console.log(productValue)
          setProductData(productValue);
          const productSKUs = productValue?.salesProjection?.fieldProductSkuName;
          setProductSKUs(productValue?.salesProjection?.fieldProductSkuName)
          const totalSalesRevenueArrayMonths = Array.from({ length: 13 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.details[index]?.salesrevenue) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          const totalSalesRevenueArrayYear = Array.from({ length: 4 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.aggregate[index]?.salesrevenue) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          const totalSalesRevenueArrayMonthsGST = Array.from({ length: 13 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.details[index]?.gst_collected_on_sales) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          const totalSalesRevenueArrayYearGST = Array.from({ length: 4 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.aggregate[index]?.gst_collected_on_sales) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          const totalVariableArrayMonths = Array.from({ length: 13 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.details[index]?.variableCostB4GST) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          const totalVariableArrayYear = Array.from({ length: 4 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.aggregate[index]?.variableCostB4GST) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          const totalVariableArrayMonthsGST = Array.from({ length: 13 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.details[index]?.variableCostGST) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          const totalVariableArrayYearGST = Array.from({ length: 4 }, (_, index) =>
            productSKUs?.reduce((sum, sku) => {
              const salesRevenue = parseFloat(sku.aggregate[index]?.variableCostGST) || 0;
              return sum + salesRevenue;
            }, 0)
          );
          setTotalSalesRevenueArrayYear(totalSalesRevenueArrayYear);
          setTotalSalesRevenueArrayMonths(totalSalesRevenueArrayMonths);
          setTotalSalesRevenueArrayYearGST(totalSalesRevenueArrayYearGST);
          setTotalSalesRevenueArrayMonthsGST(totalSalesRevenueArrayMonthsGST);
          setTotalVariableArrayYear(totalVariableArrayYear);
          setTotalVariableArrayMonths(totalVariableArrayMonths);
          setTotalVariableArrayYearGST(totalVariableArrayYearGST);
          setTotalVariableArrayMonthsGST(totalVariableArrayMonthsGST);
          console.log('income sale revenue completed')  

        }
        )
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);
  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.table_to_sheet(document.getElementById("data-table"));
      // const worksheet = xlsx.utils.json_to_sheet(allItemData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, 'Income_Sales_Revenue');
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        // let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_TYPE = 'application/vnd.ms-excel';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };
  if (isLoader) {
    return (
      <Loader></Loader>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col-6">
          <h5>Sales Revenue </h5>
        </div>
        <div className="col-6">
          {/* <button type="button" className="btn btn-outline-primary mb-2 float-end" onClick={exportExcel}>
      Excel Export
                  </button> */}
        </div>
      </div>
      <div id="data-table">
        <div className="table-responsive">
          <table className="table table-bordered data-table">
            <thead className="table-light">
              <tr>
                <th className="text-center md-width">Description</th>
                <th className="text-center md-width">Month 1</th>
                <th className="text-center md-width">Month 2</th>
                <th className="text-center md-width">Month 3</th>
                <th className="text-center md-width">Month 4</th>
                <th className="text-center md-width">Month 5</th>
                <th className="text-center md-width">Month 6</th>
                <th className="text-center md-width">Month 7</th>
                <th className="text-center md-width">Month 8</th>
                <th className="text-center md-width">Month 9</th>
                <th className="text-center md-width">Month 10</th>
                <th className="text-center md-width">Month 11</th>
                <th className="text-center md-width">Month 12</th>
                <th className="text-center md-width">Year 1</th>
                <th className="text-center md-width">Year 2</th>
                <th className="text-center md-width">Year 3</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              <tr>
                <td className="text-left lg-width" colSpan={18}><strong>SKU Sales Qty</strong></td>
              </tr>
              {productSKUs?.map(data => (
                <tr key={data?._id}>
                <td className="text-left">{data?.name}</td>
                <td className="text-right">{getNumericFormat(data?.details[1]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[2]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[3]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[4]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[5]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[6]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[7]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[8]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[9]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[10]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[11]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.details[12]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.aggregate[1]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.aggregate[2]?.quantity)}</td>
                <td className="text-right">{getNumericFormat(data?.aggregate[3]?.quantity)}</td>
              </tr>))}
              <tr className="page-break">
                <td className="text-left" colSpan={18}><strong>Sales Revenue Before GST</strong></td>
              </tr>
              {productSKUs?.map(data => (
                <tr key={data?._id}>
                  <td className="text-left">{data?.name}</td>
                  <td className="text-right">{getNumericFormat(data?.details[1]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[2]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[3]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[4]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[5]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[6]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[7]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[8]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[9]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[10]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[11]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[12]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[1]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[2]?.salesrevenue)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[3]?.salesrevenue)}</td>
                </tr>
              ))}
              <tr className="page-break">
                <td className="text-left" colSpan={18}><strong>GST On Sales</strong></td>
              </tr>
              {productSKUs?.map(data => (
                <tr key={data?._id}>
                  <td className="text-left">{data?.name}</td>
                  <td className="text-right">{getNumericFormat(data?.details[1]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[2]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[3]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[4]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[5]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[6]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[7]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[8]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[9]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[10]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[11]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[12]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[1]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[2]?.gst_collected_on_sales)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[3]?.gst_collected_on_sales)}</td>
                </tr>
              ))}
              <tr className="page-break"> 
                <td className="text-left" colSpan={18}><strong>Poduct Summary</strong></td>
              </tr>
              <tr>
                <td className="text-left lg-width">Sales Revenue before GST</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[3])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[4])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[5])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[6])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[7])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[8])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[9])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[10])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[11])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonths?.[12])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayYear?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayYear?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayYear?.[3])}</td>
              </tr>
              <tr>
                <td className="text-left lg-width">Sales Revenue before GST</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[3])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[4])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[5])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[6])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[7])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[8])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[9])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[10])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[11])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayMonthsGST?.[12])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayYearGST?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayYearGST?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalSalesRevenueArrayYearGST?.[3])}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h5 className="page-break">Variable Cost Details</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th className="text-center md-width">Description</th>
                <th className="text-center md-width">Month 1</th>
                <th className="text-center md-width">Month 2</th>
                <th className="text-center md-width">Month 3</th>
                <th className="text-center md-width">Month 4</th>
                <th className="text-center md-width">Month 5</th>
                <th className="text-center md-width">Month 6</th>
                <th className="text-center md-width">Month 7</th>
                <th className="text-center md-width">Month 8</th>
                <th className="text-center md-width">Month 9</th>
                <th className="text-center md-width">Month 10</th>
                <th className="text-center md-width">Month 11</th>
                <th className="text-center md-width">Month 12</th>
                <th className="text-center md-width">Year 1</th>
                <th className="text-center md-width">Year 2</th>
                <th className="text-center md-width">Year 3</th>
              </tr>
            </thead>
            <tbody>
              <tr className="page-break">
                <td className="text-left lg-width" colSpan={18}><strong>Variable Cost Before GST</strong></td>
              </tr>
              {productSKUs?.map(data => (
                <tr key={data?._id}>
                  <td className="text-left">{data?.name}</td>
                  <td className="text-right">{getNumericFormat(data?.details[1]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[2]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[3]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[4]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[5]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[6]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[7]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[8]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[9]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[10]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[11]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[12]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[1]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[2]?.variableCostB4GST)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[3]?.variableCostB4GST)}</td>
                </tr>
              ))}
              <tr className="page-break">
                <td className="text-left" colSpan={18}><strong>GST in Variable Cost</strong></td>
              </tr>
              {productSKUs?.map(data => (
                <tr key={data?._id}>
                  <td className="text-left">{data?.name}</td>
                  <td className="text-right">{getNumericFormat(data?.details[1]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[2]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[3]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[4]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[5]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[6]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[7]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[8]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[9]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[10]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[11]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.details[12]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[1]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[2]?.variableCostGST)}</td>
                  <td className="text-right">{getNumericFormat(data?.aggregate[3]?.variableCostGST)}</td>
                </tr>
              ))}
              <tr className="page-break">
                <td className="text-left" colSpan={18}><strong>Product Summary</strong></td>
              </tr>
              <tr>
                <td className="text-left lg-width">Total Variable Cost Before GST</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[3])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[4])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[5])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[6])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[7])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[8])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[9])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[10])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[11])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[12])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYear?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYear?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYear?.[3])}</td>
              </tr>
              <tr>
                <td className="lg-width">Total Variable Cost GST</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[3])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[4])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[5])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[6])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[7])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[8])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[9])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[10])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[11])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonthsGST?.[12])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYearGST?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYearGST?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYearGST?.[3])}</td>
              </tr>
              <tr>
                <td className="lg-width"><strong>Total</strong> </td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[1] + totalVariableArrayMonthsGST?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[2] + totalVariableArrayMonthsGST?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[3] + totalVariableArrayMonthsGST?.[3])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[4] + totalVariableArrayMonthsGST?.[4])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[5] + totalVariableArrayMonthsGST?.[5])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[6] + totalVariableArrayMonthsGST?.[6])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[7] + totalVariableArrayMonthsGST?.[7])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[8] + totalVariableArrayMonthsGST?.[8])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[9] + totalVariableArrayMonthsGST?.[9])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[10] + totalVariableArrayMonthsGST?.[10])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[11] + totalVariableArrayMonthsGST?.[11])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayMonths?.[12] + totalVariableArrayMonthsGST?.[12])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYear?.[1] + totalVariableArrayYearGST?.[1])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYear?.[2] + totalVariableArrayYearGST?.[2])}</td>
                <td className="text-right">{getNumericFormat(totalVariableArrayYear?.[3] + totalVariableArrayYearGST?.[3])}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default IncomeSalesRevenue;
