import { useParams } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";

const getNumericFormat = (num) => {
  const numberFormatter = Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 0,
  });
  return num ? numberFormatter.format(num) : 0;
};

function IncomeStatement(props) {
  const operation = useParams().op;
  const params = useParams();
  const [productData, setProductData] = useState();
  const [isLoader, setIsLoader] = useState(false);

  const [fixedCostExcludingInterest, setFixedCostExcludingInterest] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fixedCostInterest, setFixedCostInterest] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fixedCostNonCash, setFixedCostNonCash] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalFixedExpense, setTotalFixedExpense] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalSalesRevenue, setTotalSalesRevenue] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalVariableCost, setTotalVariableCost] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [grossProfit, setGrossProfit] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netGSTOnSale, setNetGSTOnSale] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [taxPaid, setTaxPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [commission, setCommission] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fgTransport, setFgTransport] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [creditCardFee, setCreditCardFee] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalInterest, setTotalInterest] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [otherIncome, setOtherIncome] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });

  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {

          setIsLoader(false);
          // let productValue;
          // if (data.data[0].manufacturingIds) {
          //   setProductData(data.data[0].manufacturingIds)
          //   productValue = data.data[0].manufacturingIds;
          // } else if (data.data[0].trading) {
          //   setProductData(data.data[0].trading)
          //   productValue = data.data[0].trading;
          // } else if (data.data[0].service) {
          //   setProductData(data.data[0].service)
          //   productValue = data.data[0].service;
          // } else if (data.data[0].animalhusbandry) {
          //   setProductData(data.data[0].animalhusbandry)
          //   productValue = data.data[0].animalhusbandry;
          // } else if (data.data[0].agro) {
          //   setProductData(data.data[0].agro)
          //   productValue = data.data[0].agro;
          // } else {

          // }
          let productValue;
          let businessTypes = [];
          if (data.data[0].manufacturingIds) {
            businessTypes.push(data.data[0].manufacturingIds)
          }
          if (data.data[0].trading) {
            businessTypes.push(data.data[0].trading)
          }
          if (data.data[0].service) {
            businessTypes.push(data.data[0].service)
          }
          if (data.data[0].animalhusbandry) {
            businessTypes.push(data.data[0].animalhusbandry)
          }
          if (data.data[0].agro) {
            businessTypes.push(data.data[0].agro)
          }
          // console.log(businessTypes)

          const combinedValue = {
            orderingFrequency: { fieldOrderingFrequencyName: [] },
            productList: [],
            rawMaterialConsumption: { rawMaterial: [] },
            rawMaterialProcurement: {
              fieldProductRMName: [],
              initialInputCost: 0,
              initialInputCostGST: 0,
              monthlyInputGSTPaid: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              monthlyInputPurchaseB4GST: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              yearlyInputGSTPaid: {
                1: 0,
                2: 0,
                3: 0,
              },
              yearlyInputPurchaseB4GST: {
                1: 0,
                2: 0,
                3: 0,
              }
            },
            salesProjection: {
              fieldProductSkuName: [],
              fieldProductsName: [],
              totalMonthlyCashInflowOfSales: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSales: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyCashInflowOfSalesGST: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSalesGST: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyGSTCollectOnSale: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyGSTCollectOnSale: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlySalesRevenue: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlySalesRevenue: {
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyValues: {
                details: {
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  4: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  5: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  6: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  7: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  8: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  9: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  10: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  11: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  12: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },

                },
                aggregate: {
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                }

              },
              creditCardFee: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                },
              },
              creditCardFeeCashInFlow: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                },
              }

            },
          };


          for (let type = 0; type < businessTypes.length; type++) {
            // console.log(type);
            // console.log(businessTypes[type])
            //orderFrequency
            combinedValue.orderingFrequency.fieldOrderingFrequencyName = [
              ...(combinedValue?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
              ...(businessTypes?.[type]?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
            ];
            // console.log(businessTypes[type])

            //productList
            combinedValue.productList = [...(combinedValue?.productList), ...businessTypes[type]?.productList];
            // console.log(businessTypes[type])

            //consumption
            combinedValue.rawMaterialConsumption.rawMaterial = [
              ...(combinedValue?.rawMaterialConsumption?.rawMaterial ?? []),
              ...(businessTypes[type].rawMaterialConsumption?.rawMaterial ?? []),
            ];
            // console.log(businessTypes[type])

            //procurement
            combinedValue.rawMaterialProcurement.fieldProductRMName = [
              ...(combinedValue?.rawMaterialProcurement?.fieldProductRMName ?? []),
              ...(businessTypes[type].rawMaterialProcurement?.fieldProductRMName ?? []),
            ];
            combinedValue.rawMaterialProcurement.initialInputCost += businessTypes[type].rawMaterialProcurement?.initialInputCost ? parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCost) : 0;
            combinedValue.rawMaterialProcurement.initialInputCostGST += businessTypes[type].rawMaterialProcurement?.initialInputCostGST ? parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCostGST) : 0;
            for (let month = 1; month <= 12; month++) {
              combinedValue.rawMaterialProcurement.monthlyInputGSTPaid[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month] ? parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]) : 0;
              combinedValue.rawMaterialProcurement.monthlyInputPurchaseB4GST[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month] ? parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]) : 0;
            }
            // console.log('this')
            for (let year = 1; year <= 3; year++) {
              combinedValue.rawMaterialProcurement.yearlyInputGSTPaid[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year] ? parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]) : 0;
              combinedValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year] ? parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]) : 0;
            }
            // console.log(businessTypes[type])

            //salesProjection
            combinedValue.salesProjection.fieldProductSkuName = [
              ...(combinedValue?.salesProjection?.fieldProductSkuName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductSkuName ?? []),
            ];
            combinedValue.salesProjection.fieldProductsName = [
              ...(combinedValue?.salesProjection?.fieldProductsName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductsName ?? []),
            ];

            for (let month = 1; month <= 12; month++) {
              combinedValue.salesProjection.totalMonthlyCashInflowOfSales[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlyCashInflowOfSalesGST[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlyGSTCollectOnSale[month] += businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlySalesRevenue[month] += businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month] ? parseFloat(businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].commission += businessTypes[type].salesProjection?.totalMonthlyValues?.details?.[month]?.commission ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].commission) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].transport += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales) : 0;
              combinedValue.salesProjection.creditCardFee.details[month] += businessTypes[type].salesProjection?.creditCardFee?.details?.[month] ? parseFloat(businessTypes[type].salesProjection?.creditCardFee.details[month]) : 0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.details[month] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.details?.[month] ? parseFloat(businessTypes[type].salesProjection?.creditCardFeeCashInFlow.details[month]) : 0;
            }
            for (let year = 1; year <= 3; year++) {
              combinedValue.salesProjection.totalYearlyCashInFlowOfSales[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyCashInFlowOfSalesGST[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyGSTCollectOnSale[year] += businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year]) : 0;
              combinedValue.salesProjection.totalYearlySalesRevenue[year] += businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year]) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].commission += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].transport += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales) : 0;
              combinedValue.salesProjection.creditCardFee.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFee?.aggregate?.[year] ? parseFloat(businessTypes[type].salesProjection?.creditCardFee.aggregate[year]) : 0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[year] ? parseFloat(businessTypes[type].salesProjection?.creditCardFeeCashInFlow.aggregate[year]) : 0;

            }
            // console.log(type, combinedValue)

          }

          productValue = combinedValue;
          // console.log(productValue)
          setProductData(productValue);
          setCreditCardFee(productValue?.salesProjection?.creditCardFee)


          let interest = totalInterest;
          let cashExcludingInterest = fixedCostExcludingInterest;
          let cashInterest = fixedCostInterest;
          let nonCash = fixedCostNonCash;
          let totalFixedCost = totalFixedExpense;
          let salesCommission = commission;
          let transportTotal = fgTransport;
          data.data[0].fixedCost.fieldLineItemsName.filter((x, index) => {
            totalFixedCost.details['gestPeriod'] += parseFloat(x.details['gestPeriod']);
            cashExcludingInterest.details['gestPeriod'] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest" ? parseFloat(x.details['gestPeriod']) : 0;
            cashInterest.details['gestPeriod'] += x.lineItemName == "Interest" ? parseFloat(x.details['gestPeriod']) : 0;

            for (let i = 1; i <= 12; i++) {
              cashExcludingInterest.details[i] += (x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest") ? (isNaN(parseFloat(x.details[i])) ? 0 : parseFloat(x.details[i])) : 0;
              cashInterest.details[i] += (x.lineItemName === "Interest") ? (isNaN(parseFloat(x.details[i])) ? 0 : parseFloat(x.details[i])) : 0;
              nonCash.details[i] += (x.lineItemName === "Dep + Amort (Non Cash)" || x.lineItemName === "Addtnl Dep (Non Cash)") ?
                (isNaN(parseFloat(x.details[i])) ? 0 : parseFloat(x.details[i])) : 0;
              totalFixedCost.details[i] = (typeof totalFixedCost.details[i] !== 'undefined') ? ((isNaN(parseFloat(totalFixedCost.details[i])) ? 0 : parseFloat(totalFixedCost.details[i])) + (isNaN(parseFloat(x.details[i])) ? 0 : parseFloat(x.details[i]))) : 0;
              // totalCash.details[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? parseFloat(x.details[i]) : 0;

            }

            for (let i = 1; i <= 3; i++) {
              cashExcludingInterest.aggregate[i] += (x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest") ? (isNaN(parseFloat(x.aggregate[i])) ? 0 : parseFloat(x.aggregate[i])) : 0;
              cashInterest.aggregate[i] += (x.lineItemName === "Interest") ? (isNaN(parseFloat(x.aggregate[i])) ? 0 : parseFloat(x.aggregate[i])) : 0;
              nonCash.aggregate[i] += (x.lineItemName === "Dep + Amort (Non Cash)" || x.lineItemName === "Addtnl Dep (Non Cash)") ? (isNaN(parseFloat(x.aggregate[i])) ? 0 : parseFloat(x.aggregate[i])) : 0;
              totalFixedCost.aggregate[i] = (typeof totalFixedCost.aggregate[i] !== 'undefined') ? ((isNaN(parseFloat(totalFixedCost.aggregate[i])) ? 0 : parseFloat(totalFixedCost.aggregate[i])) + (isNaN(parseFloat(x.aggregate[i])) ? 0 : parseFloat(x.aggregate[i]))) : 0;
              // totalCash.aggregate[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? parseFloat(x.aggregate[i]) : 0;

            }

          });

          const whichMonth = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMonth) ? parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMonth) : 0;
          let other = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };
          if (whichMonth > 0) {

            if (whichMonth <= 12) {
              other.details[whichMonth] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) ? parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) : 0;
              other.aggregate[1] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) ? parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) : 0;
            } else if (whichMonth <= 24) {
              other.aggregate[2] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) ? parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) : 0;
            } else if (whichMonth <= 36) {
              other.aggregate[3] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) ? parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine) : 0;

            }

            setOtherIncome(other)
          }

          setFixedCostExcludingInterest(cashExcludingInterest);
          setFixedCostInterest(cashInterest);
          setFixedCostNonCash(nonCash);
          setTotalFixedExpense(totalFixedCost);

          let totalSales = totalSalesRevenue;
          let totalVariable = totalVariableCost;
          let totalnetGSTOnSale = netGSTOnSale;
          let totalGrossProfit = grossProfit;
          let totalTaxPaid = taxPaid;
          let taxRateYear1 = data.data[0].businessInfoid.taxRateYear1 ? data.data[0].businessInfoid.taxRateYear1 : 0;
          let taxRateYear2 = data.data[0].businessInfoid.taxRateYear2 ? data.data[0].businessInfoid.taxRateYear2 : 0;
          let taxRateYear3 = data.data[0].businessInfoid.taxRateYear3 ? data.data[0].businessInfoid.taxRateYear3 : 0;


          // let taxRateYear1 = 6;
          // let taxRateYear2 = 8;
          // let taxRateYear3 = 10;
          taxPaid.details['gestPeriod'] = 0;

          for (let i = 1; i <= 12; i++) {
            totalSales.details[i] = parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]);
            salesCommission.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.commission?.details[i]) || 0), 0);
            transportTotal.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.transport?.details[i]) || 0), 0);
            totalnetGSTOnSale.details[i] = parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST);
            totalVariable.details[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST) + parseFloat(totalnetGSTOnSale.details[i]);
            // interest.details[i] = parsefloat()
            totalGrossProfit.details[i] = parseFloat(totalSales.details[i]) - (parseFloat(totalVariable?.details[i]) + parseFloat(commission?.details[i]) + parseFloat(fgTransport?.details[i])) - parseFloat(productValue?.salesProjection?.creditCardFee?.details[i]);
            totalTaxPaid.details[i] = parseFloat((totalGrossProfit.details[i] + other.details[i] - totalFixedCost.details[i]) * taxRateYear1 / 100) < 0 ? 0 : parseFloat((totalGrossProfit.details[i] + other.details[i] - totalFixedCost.details[i]) * taxRateYear1 / 100)

          }
          for (let i = 1; i <= 3; i++) {
            salesCommission.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.commission?.aggregate[i]) || 0), 0);
            transportTotal.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.transport?.aggregate[i]) || 0), 0);

            totalSales.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]);
            totalnetGSTOnSale.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST);
            totalVariable.aggregate[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST) + parseFloat(totalnetGSTOnSale.aggregate[i]);
            totalGrossProfit.aggregate[i] = parseFloat(totalSales.aggregate[i]) - (parseFloat(totalVariable?.aggregate[i]) + parseFloat(commission?.aggregate[i]) + parseFloat(fgTransport?.aggregate[i])) - parseFloat(productValue?.salesProjection?.creditCardFee?.aggregate[i]);
            let taxRate = i == 1 ? taxRateYear1 : i == 2 ? taxRateYear2 : taxRateYear3;
            // console.log(taxRate)
            // totalTaxPaid.aggregate[i] = (parseFloat(totalGrossProfit.aggregate[i])+parseFloat(other.aggregate[i]) - parseFloat(totalFixedCost.aggregate[i])) * taxRate / 100 < 0 ? 0 : (parseFloat(totalGrossProfit.aggregate[i])+parseFloat(otherIncome.aggregate[i]) - parseFloat(totalFixedCost.aggregate[i])) * taxRate / 100;
            totalTaxPaid.aggregate[i] = (parseFloat(totalGrossProfit.aggregate[i]) + parseFloat(other.aggregate[i]) - parseFloat(totalFixedCost.aggregate[i])) * taxRate / 100 < 0 ? 0 : (parseFloat(totalGrossProfit.aggregate[i]) + parseFloat(other.aggregate[i]) - parseFloat(totalFixedCost.aggregate[i])) * taxRate / 100

            // console.log(totalTaxPaid.aggregate[i])
            // console.log(i, parseFloat(totalGrossProfit.aggregate[i]), parseFloat(other.aggregate[i]), parseFloat(totalFixedCost.aggregate[i]), taxRate);
          }
          setTotalSalesRevenue(totalSales);
          setNetGSTOnSale(totalnetGSTOnSale);
          setTotalVariableCost(totalVariable);
          setGrossProfit(totalGrossProfit);
          setTaxPaid(totalTaxPaid);
          setCommission(salesCommission);
          setFgTransport(transportTotal);
          setOtherIncome(other);
          console.log('income statment completed')  

        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);



  if (isLoader) {
    return (
      <Loader></Loader>
    );
  }

  return (
    <>
      <h5 className={props.isPrintable ? "hidden" : ""}>Proforma Income Statement</h5>
      <div className={props.isPrintable ? "hidden table-responsive" : "table-responsive"}>
        <table className="table table-bordered">
          <thead className="table-light">
            <tr>
              <th className="text-center md-width"></th>
              <th className="text-center lg-width">Description</th>
              <th className="text-center md-width">Startup</th>
              <th className="text-center md-width">Month 1</th>
              <th className="text-center md-width">Month 2</th>
              <th className="text-center md-width">Month 3</th>
              <th className="text-center md-width">Month 4</th>
              <th className="text-center md-width">Month 5</th>
              <th className="text-center md-width">Month 6</th>
              <th className="text-center md-width">Month 7</th>
              <th className="text-center md-width">Month 8</th>
              <th className="text-center md-width">Month 9</th>
              <th className="text-center md-width">Month 10</th>
              <th className="text-center md-width">Month 11</th>
              <th className="text-center md-width">Month 12</th>
              <th className="text-center md-width">Year 1</th>
              <th className="text-center md-width">Year 2</th>
              <th className="text-center md-width">Year 3</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            <tr>
              <td></td>
              <td colSpan="18"><strong>Income</strong></td>
            </tr>
            <tr>
              <td>A1</td>
              <td>Sales Revenue Before GST</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[3])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[4])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[5])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[6])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[7])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[8])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[9])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[10])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[11])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[12])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData.salesProjection.totalYearlySalesRevenue[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData.salesProjection.totalYearlySalesRevenue[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData.salesProjection.totalYearlySalesRevenue[3])}</td>

            </tr>
            <tr>
              <td>A2</td>
              <td>GST on Sales(Output GST)</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[3])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[4])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[5])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[6])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[7])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[8])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[9])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[10])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[11])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[12])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalYearlyGSTCollectOnSale[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalYearlyGSTCollectOnSale[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalYearlyGSTCollectOnSale[3])}</td>
            </tr>

            <tr>
              <td>A3 = A1+A2</td>
              <td>Total Sales</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[12])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Variable Cost</strong></td>
            </tr>
            <tr>
              <td>B1</td>
              <td>Material</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[1].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[2].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[3].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[4].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[5].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[6].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[7].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[8].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[9].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[10].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[11].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[12].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[1].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[2].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[3].variableCostB4GST)}</td>
            </tr>
            <tr>
              <td>B2</td>
              <td>GST on Material(Input GST)</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[1].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[2].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[3].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[4].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[5].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[6].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[7].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[8].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[9].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[10].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[11].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[12].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[1].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[2].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[3].variableCostGST)}</td>
            </tr>
            <tr>
              <td>B3 = A2-B2</td>
              <td>Net GST on Sale</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[1])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[2])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[3])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[4])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[5])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[6])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[7])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[8])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[9])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[10])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[11])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[12])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.aggregate[3])}</td>
            </tr>
            <tr>
              <td>B1+B2+B3</td>
              <td>Sub Total Material</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[12])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Sales Commission </td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(commission.details[1])}</td>
              <td className="text-right">{getNumericFormat(commission.details[2])}</td>
              <td className="text-right">{getNumericFormat(commission.details[3])}</td>
              <td className="text-right">{getNumericFormat(commission.details[4])}</td>
              <td className="text-right">{getNumericFormat(commission.details[5])}</td>
              <td className="text-right">{getNumericFormat(commission.details[6])}</td>
              <td className="text-right">{getNumericFormat(commission.details[7])}</td>
              <td className="text-right">{getNumericFormat(commission.details[8])}</td>
              <td className="text-right">{getNumericFormat(commission.details[9])}</td>
              <td className="text-right">{getNumericFormat(commission.details[10])}</td>
              <td className="text-right">{getNumericFormat(commission.details[11])}</td>
              <td className="text-right">{getNumericFormat(commission.details[12])}</td>
              <td className="text-right">{getNumericFormat(commission.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(commission.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(commission.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>FG Transport</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[1])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[2])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[3])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[4])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[5])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[6])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[7])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[8])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[9])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[10])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[11])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[12])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td >Credit Card fee</td>
              <td className="text-center"> - </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[1] ? productData?.salesProjection?.creditCardFee?.details?.[1] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[2] ? productData?.salesProjection?.creditCardFee?.details?.[2] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[3] ? productData?.salesProjection?.creditCardFee?.details?.[3] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[4] ? productData?.salesProjection?.creditCardFee?.details?.[4] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[5] ? productData?.salesProjection?.creditCardFee?.details?.[5] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[6] ? productData?.salesProjection?.creditCardFee?.details?.[6] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[7] ? productData?.salesProjection?.creditCardFee?.details?.[7] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[8] ? productData?.salesProjection?.creditCardFee?.details?.[8] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[9] ? productData?.salesProjection?.creditCardFee?.details?.[9] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[10] ? productData?.salesProjection?.creditCardFee?.details?.[10] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[11] ? productData?.salesProjection?.creditCardFee?.details?.[11] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[12] ? productData?.salesProjection?.creditCardFee?.details?.[12] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.aggregate?.[1] ? productData?.salesProjection?.creditCardFee?.aggregate?.[1] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.aggregate?.[2] ? productData?.salesProjection?.creditCardFee?.aggregate?.[2] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.aggregate?.[3] ? productData?.salesProjection?.creditCardFee?.aggregate?.[3] : 0)} </td>


            </tr>
            <tr>
              <td></td>
              <td>Total Variable Cost</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[1]) + parseFloat(creditCardFee.details[1]) + parseFloat(fgTransport.details[1]) + parseFloat(commission.details[1]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[2]) + parseFloat(creditCardFee.details[2]) + parseFloat(fgTransport.details[2]) + parseFloat(commission.details[2]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[3]) + parseFloat(creditCardFee.details[3]) + parseFloat(fgTransport.details[3]) + parseFloat(commission.details[3]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[4]) + parseFloat(creditCardFee.details[4]) + parseFloat(fgTransport.details[4]) + parseFloat(commission.details[4]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[5]) + parseFloat(creditCardFee.details[5]) + parseFloat(fgTransport.details[5]) + parseFloat(commission.details[5]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[6]) + parseFloat(creditCardFee.details[6]) + parseFloat(fgTransport.details[6]) + parseFloat(commission.details[6]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[7]) + parseFloat(creditCardFee.details[7]) + parseFloat(fgTransport.details[7]) + parseFloat(commission.details[7]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[8]) + parseFloat(creditCardFee.details[8]) + parseFloat(fgTransport.details[8]) + parseFloat(commission.details[8]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[9]) + parseFloat(creditCardFee.details[9]) + parseFloat(fgTransport.details[9]) + parseFloat(commission.details[9]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[10]) + parseFloat(creditCardFee.details[10]) + parseFloat(fgTransport.details[10]) + parseFloat(commission.details[10]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[11]) + parseFloat(creditCardFee.details[11]) + parseFloat(fgTransport.details[11]) + parseFloat(commission.details[11]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[12]) + parseFloat(creditCardFee.details[12]) + parseFloat(fgTransport.details[12]) + parseFloat(commission.details[12]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.aggregate[1]) + parseFloat(creditCardFee.aggregate[1]) + parseFloat(fgTransport.aggregate[1]) + parseFloat(commission.aggregate[1]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.aggregate[2]) + parseFloat(creditCardFee.aggregate[2]) + parseFloat(fgTransport.aggregate[2]) + parseFloat(commission.aggregate[2]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.aggregate[3]) + parseFloat(creditCardFee.aggregate[3]) + parseFloat(fgTransport.aggregate[3]) + parseFloat(commission.aggregate[3]))}</td>
            </tr>


            <tr>
              <td>A3 - (B1 + B2 + B3)</td>
              <td>Gross Profit (Gross Margin)</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[3])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[4])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[5])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[6])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[7])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[8])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[9])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[10])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[11])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[12])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Gross Profit as % of sales</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[1] / totalSalesRevenue.details[1]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[2] / totalSalesRevenue.details[2]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[3] / totalSalesRevenue.details[3]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[4] / totalSalesRevenue.details[4]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[5] / totalSalesRevenue.details[5]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[6] / totalSalesRevenue.details[6]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[7] / totalSalesRevenue.details[7]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[8] / totalSalesRevenue.details[8]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[9] / totalSalesRevenue.details[9]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[10] / totalSalesRevenue.details[10]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[11] / totalSalesRevenue.details[11]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[12] / totalSalesRevenue.details[12]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] / totalSalesRevenue.aggregate[1]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] / totalSalesRevenue.aggregate[2]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] / totalSalesRevenue.aggregate[3]) * 100)}%</td>
            </tr>
            <tr>
              <td></td>
              <td>Other Income</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[12])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.aggregate[3])}</td>

            </tr>

            <tr>
              <td></td>
              <td>Interest (Cash)</td>
              <td className="text-center">{getNumericFormat(fixedCostInterest.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[12])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Excluding Interest (Cash)</td>
              <td className="text-center">{getNumericFormat(fixedCostExcludingInterest.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[12])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.aggregate[3])}</td>
            </tr>

            <tr>
              <td></td>
              <td>Dep + Amort (Non Cash)</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[12])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[3])}</td>
            </tr>
            <tr>
              <td>C</td>
              <td>Total Fixed Expense</td>
              <td className="text-center">{getNumericFormat(totalFixedExpense.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[12])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.aggregate[3])}</td>
            </tr>
            <tr>
              <td>D</td>
              <td>Profit Before Tax</td>
              <td className="text-center">{getNumericFormat(0 - totalFixedExpense.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[1] - totalFixedExpense.details[1] + otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[2] - totalFixedExpense.details[2] + otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[3] - totalFixedExpense.details[3] + otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[4] - totalFixedExpense.details[4] + otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[5] - totalFixedExpense.details[5] + otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[6] - totalFixedExpense.details[6] + otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[7] - totalFixedExpense.details[7] + otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[8] - totalFixedExpense.details[8] + otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[9] - totalFixedExpense.details[9] + otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[10] - totalFixedExpense.details[10] + otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[11] - totalFixedExpense.details[11] + otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[12] - totalFixedExpense.details[12] + otherIncome.details[12])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[1] - totalFixedExpense.aggregate[1] + otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[2] - totalFixedExpense.aggregate[2] + otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[3] - totalFixedExpense.aggregate[3] + otherIncome.aggregate[3])}</td>
            </tr>
            <tr>
              <td>E</td>
              <td>Tax</td>
              <td className="text-center">{getNumericFormat(taxPaid.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[1])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[2])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[3])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[4])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[5])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[6])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[7])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[8])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[9])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[10])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[11])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[12])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[3])}</td>
            </tr>
            <tr>
              <td>D - E = F</td>
              <td>Profit After Tax</td>
              <td className="text-center">{getNumericFormat(taxPaid.details['gestPeriod'] - totalFixedExpense.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[1] - totalFixedExpense.details[1]) - taxPaid.details[1] + otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[2] - totalFixedExpense.details[2]) - taxPaid.details[2] + otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[3] - totalFixedExpense.details[3]) - taxPaid.details[3] + otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[4] - totalFixedExpense.details[4]) - taxPaid.details[4] + otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[5] - totalFixedExpense.details[5]) - taxPaid.details[5] + otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[6] - totalFixedExpense.details[6]) - taxPaid.details[6] + otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[7] - totalFixedExpense.details[7]) - taxPaid.details[7] + otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[8] - totalFixedExpense.details[8]) - taxPaid.details[8] + otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[9] - totalFixedExpense.details[9]) - taxPaid.details[9] + otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[10] - totalFixedExpense.details[10]) - taxPaid.details[10] + otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[11] - totalFixedExpense.details[11]) - taxPaid.details[11] + otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[12] - totalFixedExpense.details[12]) - taxPaid.details[12] + otherIncome.details[12])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1]) - taxPaid.aggregate[1] + otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2]) - taxPaid.aggregate[2] + otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3]) - taxPaid.aggregate[3] + otherIncome.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>EBIDTA</td>
              <td className="text-center">{getNumericFormat((0 - totalFixedExpense.details['gestPeriod'] - 0) + 0 + 0 + fixedCostInterest.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[1] - totalFixedExpense.details[1] - taxPaid.details[1]) + taxPaid.details[1] + fixedCostNonCash.details[1] + fixedCostInterest.details[1] + otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[2] - totalFixedExpense.details[2] - taxPaid.details[2]) + taxPaid.details[2] + fixedCostNonCash.details[2] + fixedCostInterest.details[2] + otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[3] - totalFixedExpense.details[3] - taxPaid.details[3]) + taxPaid.details[3] + fixedCostNonCash.details[3] + fixedCostInterest.details[3] + otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[4] - totalFixedExpense.details[4] - taxPaid.details[4]) + taxPaid.details[4] + fixedCostNonCash.details[4] + fixedCostInterest.details[4] + otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[5] - totalFixedExpense.details[5] - taxPaid.details[5]) + taxPaid.details[5] + fixedCostNonCash.details[5] + fixedCostInterest.details[5] + otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[6] - totalFixedExpense.details[6] - taxPaid.details[6]) + taxPaid.details[6] + fixedCostNonCash.details[6] + fixedCostInterest.details[6] + otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[7] - totalFixedExpense.details[7] - taxPaid.details[7]) + taxPaid.details[7] + fixedCostNonCash.details[7] + fixedCostInterest.details[7] + otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[8] - totalFixedExpense.details[8] - taxPaid.details[8]) + taxPaid.details[8] + fixedCostNonCash.details[8] + fixedCostInterest.details[8] + otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[9] - totalFixedExpense.details[9] - taxPaid.details[9]) + taxPaid.details[9] + fixedCostNonCash.details[9] + fixedCostInterest.details[9] + otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[10] - totalFixedExpense.details[10] - taxPaid.details[10]) + taxPaid.details[10] + fixedCostNonCash.details[10] + fixedCostInterest.details[10] + otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[11] - totalFixedExpense.details[11] - taxPaid.details[11]) + taxPaid.details[11] + fixedCostNonCash.details[11] + fixedCostInterest.details[11] + otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[12] - totalFixedExpense.details[12] - taxPaid.details[12]) + taxPaid.details[12] + fixedCostNonCash.details[12] + fixedCostInterest.details[12] + otherIncome.details[12])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1] - taxPaid.aggregate[1]) + taxPaid.aggregate[1] + fixedCostNonCash.aggregate[1] + fixedCostInterest.aggregate[1] + otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2] - taxPaid.aggregate[2]) + taxPaid.aggregate[2] + fixedCostNonCash.aggregate[2] + fixedCostInterest.aggregate[2] + otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3] - taxPaid.aggregate[3]) + taxPaid.aggregate[3] + fixedCostNonCash.aggregate[3] + fixedCostInterest.aggregate[3] + otherIncome.aggregate[3])}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <IncomeStatementPrint totalSalesRevenue={totalSalesRevenue} netGSTOnSale={netGSTOnSale} totalVariableCost={totalVariableCost} commission={commission} productData={productData} fgTransport={fgTransport} creditCardFee={creditCardFee} grossProfit={grossProfit} otherIncome={otherIncome} taxPaid={taxPaid} fixedCostInterest={fixedCostInterest} fixedCostExcludingInterest={fixedCostExcludingInterest} fixedCostNonCash={fixedCostNonCash} totalFixedExpense={totalFixedExpense} />
    </>
  );
}
function IncomeStatementPrint({ totalSalesRevenue, netGSTOnSale, totalVariableCost, commission, productData, fgTransport, creditCardFee, grossProfit, otherIncome, taxPaid, fixedCostInterest, fixedCostExcludingInterest, fixedCostNonCash, totalFixedExpense}) {

  return (
    <>
      <div class="income-statement-print print-hide table-transform">
      <h5>Income Statement 1st year monthly</h5>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-light">
            <tr>
              <th className="text-center md-width"></th>
              <th className="text-center lg-width">Description</th>
              <th className="text-center md-width">Startup</th>
              <th className="text-center md-width">Month 1</th>
              <th className="text-center md-width">Month 2</th>
              <th className="text-center md-width">Month 3</th>
              <th className="text-center md-width">Month 4</th>
              <th className="text-center md-width">Month 5</th>
              <th className="text-center md-width">Month 6</th>
              <th className="text-center md-width">Month 7</th>
              <th className="text-center md-width">Month 8</th>
              <th className="text-center md-width">Month 9</th>
              <th className="text-center md-width">Month 10</th>
              <th className="text-center md-width">Month 11</th>
              <th className="text-center md-width">Month 12</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            <tr>
              <td></td>
              <td colSpan="18"><strong>Income</strong></td>
            </tr>
            <tr>
              <td>A1</td>
              <td>Sales Revenue Before GST</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[3])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[4])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[5])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[6])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[7])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[8])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[9])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[10])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[11])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlySalesRevenue && getNumericFormat(productData.salesProjection.totalMonthlySalesRevenue[12])}</td>
            </tr>
            <tr>
              <td>A2</td>
              <td>GST on Sales(Output GST)</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[3])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[4])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[5])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[6])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[7])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[8])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[9])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[10])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[11])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalMonthlyGSTCollectOnSale[12])}</td>
            </tr>

            <tr>
              <td>A3 = A1+A2</td>
              <td>Total Sales</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.details[12])}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Variable Cost</strong></td>
            </tr>
            <tr>
              <td>B1</td>
              <td>Material</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[1].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[2].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[3].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[4].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[5].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[6].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[7].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[8].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[9].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[10].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[11].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[12].variableCostB4GST)}</td>
            </tr>
            <tr>
              <td>B2</td>
              <td>GST on Material(Input GST)</td>
              <td className="text-center">-</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[1].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[2].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[3].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[4].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[5].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[6].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[7].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[8].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[9].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[10].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[11].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[12].variableCostGST)}</td>
            </tr>
            <tr>
              <td>B3 = A2-B2</td>
              <td>Net GST on Sale</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[1])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[2])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[3])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[4])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[5])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[6])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[7])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[8])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[9])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[10])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[11])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.details[12])}</td>
            </tr>
            <tr>
              <td>B1+B2+B3</td>
              <td>Sub Total Material</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.details[12])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Sales Commission </td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(commission.details[1])}</td>
              <td className="text-right">{getNumericFormat(commission.details[2])}</td>
              <td className="text-right">{getNumericFormat(commission.details[3])}</td>
              <td className="text-right">{getNumericFormat(commission.details[4])}</td>
              <td className="text-right">{getNumericFormat(commission.details[5])}</td>
              <td className="text-right">{getNumericFormat(commission.details[6])}</td>
              <td className="text-right">{getNumericFormat(commission.details[7])}</td>
              <td className="text-right">{getNumericFormat(commission.details[8])}</td>
              <td className="text-right">{getNumericFormat(commission.details[9])}</td>
              <td className="text-right">{getNumericFormat(commission.details[10])}</td>
              <td className="text-right">{getNumericFormat(commission.details[11])}</td>
              <td className="text-right">{getNumericFormat(commission.details[12])}</td>
            </tr>
            <tr>
              <td></td>
              <td>FG Transport</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[1])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[2])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[3])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[4])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[5])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[6])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[7])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[8])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[9])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[10])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[11])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.details[12])}</td>
            </tr>
            <tr>
              <td></td>
              <td >Credit Card fee</td>
              <td className="text-center"> - </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[1] ? productData?.salesProjection?.creditCardFee?.details?.[1] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[2] ? productData?.salesProjection?.creditCardFee?.details?.[2] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[3] ? productData?.salesProjection?.creditCardFee?.details?.[3] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[4] ? productData?.salesProjection?.creditCardFee?.details?.[4] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[5] ? productData?.salesProjection?.creditCardFee?.details?.[5] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[6] ? productData?.salesProjection?.creditCardFee?.details?.[6] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[7] ? productData?.salesProjection?.creditCardFee?.details?.[7] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[8] ? productData?.salesProjection?.creditCardFee?.details?.[8] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[9] ? productData?.salesProjection?.creditCardFee?.details?.[9] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[10] ? productData?.salesProjection?.creditCardFee?.details?.[10] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[11] ? productData?.salesProjection?.creditCardFee?.details?.[11] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.details?.[12] ? productData?.salesProjection?.creditCardFee?.details?.[12] : 0)} </td>


            </tr>
            <tr>
              <td></td>
              <td>Total Variable Cost</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[1]) + parseFloat(creditCardFee.details[1]) + parseFloat(fgTransport.details[1]) + parseFloat(commission.details[1]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[2]) + parseFloat(creditCardFee.details[2]) + parseFloat(fgTransport.details[2]) + parseFloat(commission.details[2]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[3]) + parseFloat(creditCardFee.details[3]) + parseFloat(fgTransport.details[3]) + parseFloat(commission.details[3]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[4]) + parseFloat(creditCardFee.details[4]) + parseFloat(fgTransport.details[4]) + parseFloat(commission.details[4]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[5]) + parseFloat(creditCardFee.details[5]) + parseFloat(fgTransport.details[5]) + parseFloat(commission.details[5]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[6]) + parseFloat(creditCardFee.details[6]) + parseFloat(fgTransport.details[6]) + parseFloat(commission.details[6]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[7]) + parseFloat(creditCardFee.details[7]) + parseFloat(fgTransport.details[7]) + parseFloat(commission.details[7]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[8]) + parseFloat(creditCardFee.details[8]) + parseFloat(fgTransport.details[8]) + parseFloat(commission.details[8]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[9]) + parseFloat(creditCardFee.details[9]) + parseFloat(fgTransport.details[9]) + parseFloat(commission.details[9]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[10]) + parseFloat(creditCardFee.details[10]) + parseFloat(fgTransport.details[10]) + parseFloat(commission.details[10]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[11]) + parseFloat(creditCardFee.details[11]) + parseFloat(fgTransport.details[11]) + parseFloat(commission.details[11]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.details[12]) + parseFloat(creditCardFee.details[12]) + parseFloat(fgTransport.details[12]) + parseFloat(commission.details[12]))}</td>
            </tr>


            <tr>
              <td>A3 - (B1 + B2 + B3)</td>
              <td>Gross Profit (Gross Margin)</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[3])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[4])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[5])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[6])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[7])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[8])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[9])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[10])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[11])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[12])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Gross Profit as % of sales</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[1] / totalSalesRevenue.details[1]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[2] / totalSalesRevenue.details[2]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[3] / totalSalesRevenue.details[3]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[4] / totalSalesRevenue.details[4]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[5] / totalSalesRevenue.details[5]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[6] / totalSalesRevenue.details[6]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[7] / totalSalesRevenue.details[7]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[8] / totalSalesRevenue.details[8]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[9] / totalSalesRevenue.details[9]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[10] / totalSalesRevenue.details[10]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[11] / totalSalesRevenue.details[11]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[12] / totalSalesRevenue.details[12]) * 100)}%</td>
            </tr>
            <tr>
              <td></td>
              <td>Other Income</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.details[12])}</td>

            </tr>

            <tr>
              <td></td>
              <td>Interest (Cash)</td>
              <td className="text-center">{getNumericFormat(fixedCostInterest.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.details[12])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Excluding Interest (Cash)</td>
              <td className="text-center">{getNumericFormat(fixedCostExcludingInterest.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.details[12])}</td>
            </tr>

            <tr>
              <td></td>
              <td>Dep + Amort (Non Cash)</td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[12])}</td>
            </tr>
            <tr>
              <td>C</td>
              <td>Total Fixed Expense</td>
              <td className="text-center">{getNumericFormat(totalFixedExpense.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.details[12])}</td>
            </tr>
            <tr>
              <td>D</td>
              <td>Profit Before Tax</td>
              <td className="text-center">{getNumericFormat(0 - totalFixedExpense.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[1] - totalFixedExpense.details[1] + otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[2] - totalFixedExpense.details[2] + otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[3] - totalFixedExpense.details[3] + otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[4] - totalFixedExpense.details[4] + otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[5] - totalFixedExpense.details[5] + otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[6] - totalFixedExpense.details[6] + otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[7] - totalFixedExpense.details[7] + otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[8] - totalFixedExpense.details[8] + otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[9] - totalFixedExpense.details[9] + otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[10] - totalFixedExpense.details[10] + otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[11] - totalFixedExpense.details[11] + otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.details[12] - totalFixedExpense.details[12] + otherIncome.details[12])}</td>
            </tr>
            <tr>
              <td>E</td>
              <td>Tax</td>
              <td className="text-center">{getNumericFormat(taxPaid.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[1])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[2])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[3])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[4])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[5])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[6])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[7])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[8])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[9])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[10])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[11])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.details[12])}</td>
            </tr>
            <tr>
              <td>D - E = F</td>
              <td>Profit After Tax</td>
              <td className="text-center">{getNumericFormat(taxPaid.details['gestPeriod'] - totalFixedExpense.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[1] - totalFixedExpense.details[1]) - taxPaid.details[1] + otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[2] - totalFixedExpense.details[2]) - taxPaid.details[2] + otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[3] - totalFixedExpense.details[3]) - taxPaid.details[3] + otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[4] - totalFixedExpense.details[4]) - taxPaid.details[4] + otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[5] - totalFixedExpense.details[5]) - taxPaid.details[5] + otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[6] - totalFixedExpense.details[6]) - taxPaid.details[6] + otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[7] - totalFixedExpense.details[7]) - taxPaid.details[7] + otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[8] - totalFixedExpense.details[8]) - taxPaid.details[8] + otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[9] - totalFixedExpense.details[9]) - taxPaid.details[9] + otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[10] - totalFixedExpense.details[10]) - taxPaid.details[10] + otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[11] - totalFixedExpense.details[11]) - taxPaid.details[11] + otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[12] - totalFixedExpense.details[12]) - taxPaid.details[12] + otherIncome.details[12])}</td>
            </tr>
            <tr>
              <td></td>
              <td>EBIDTA</td>
              <td className="text-center">{getNumericFormat((0 - totalFixedExpense.details['gestPeriod'] - 0) + 0 + 0 + fixedCostInterest.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[1] - totalFixedExpense.details[1] - taxPaid.details[1]) + taxPaid.details[1] + fixedCostNonCash.details[1] + fixedCostInterest.details[1] + otherIncome.details[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[2] - totalFixedExpense.details[2] - taxPaid.details[2]) + taxPaid.details[2] + fixedCostNonCash.details[2] + fixedCostInterest.details[2] + otherIncome.details[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[3] - totalFixedExpense.details[3] - taxPaid.details[3]) + taxPaid.details[3] + fixedCostNonCash.details[3] + fixedCostInterest.details[3] + otherIncome.details[3])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[4] - totalFixedExpense.details[4] - taxPaid.details[4]) + taxPaid.details[4] + fixedCostNonCash.details[4] + fixedCostInterest.details[4] + otherIncome.details[4])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[5] - totalFixedExpense.details[5] - taxPaid.details[5]) + taxPaid.details[5] + fixedCostNonCash.details[5] + fixedCostInterest.details[5] + otherIncome.details[5])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[6] - totalFixedExpense.details[6] - taxPaid.details[6]) + taxPaid.details[6] + fixedCostNonCash.details[6] + fixedCostInterest.details[6] + otherIncome.details[6])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[7] - totalFixedExpense.details[7] - taxPaid.details[7]) + taxPaid.details[7] + fixedCostNonCash.details[7] + fixedCostInterest.details[7] + otherIncome.details[7])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[8] - totalFixedExpense.details[8] - taxPaid.details[8]) + taxPaid.details[8] + fixedCostNonCash.details[8] + fixedCostInterest.details[8] + otherIncome.details[8])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[9] - totalFixedExpense.details[9] - taxPaid.details[9]) + taxPaid.details[9] + fixedCostNonCash.details[9] + fixedCostInterest.details[9] + otherIncome.details[9])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[10] - totalFixedExpense.details[10] - taxPaid.details[10]) + taxPaid.details[10] + fixedCostNonCash.details[10] + fixedCostInterest.details[10] + otherIncome.details[10])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[11] - totalFixedExpense.details[11] - taxPaid.details[11]) + taxPaid.details[11] + fixedCostNonCash.details[11] + fixedCostInterest.details[11] + otherIncome.details[11])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.details[12] - totalFixedExpense.details[12] - taxPaid.details[12]) + taxPaid.details[12] + fixedCostNonCash.details[12] + fixedCostInterest.details[12] + otherIncome.details[12])}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      </div>
      <div className="print-hide">
      <h5>Income Statement Annual</h5>
        <table className="table table-bordered">
          <thead className="table-light">
            <tr>
              <th className="text-center md-width"></th>
              <th className="text-center lg-width">Description</th>
              <th className="text-center md-width">Year 1</th>
              <th className="text-center md-width">Year 2</th>
              <th className="text-center md-width">Year 3</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            <tr>
              <td></td>
              <td colSpan="18"><strong>Income</strong></td>
            </tr>
            <tr>
              <td>A1</td>
              <td>Sales Revenue Before GST</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData.salesProjection.totalYearlySalesRevenue[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData.salesProjection.totalYearlySalesRevenue[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData.salesProjection.totalYearlySalesRevenue[3])}</td>

            </tr>
            <tr>
              <td>A2</td>
              <td>GST on Sales(Output GST)</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalYearlyGSTCollectOnSale[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalYearlyGSTCollectOnSale[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlyGSTCollectOnSale && getNumericFormat(productData.salesProjection.totalYearlyGSTCollectOnSale[3])}</td>
            </tr>

            <tr>
              <td>A3 = A1+A2</td>
              <td>Total Sales</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Variable Cost</strong></td>
            </tr>
            <tr>
              <td>B1</td>
              <td>Material</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[1].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[2].variableCostB4GST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[3].variableCostB4GST)}</td>
            </tr>
            <tr>
              <td>B2</td>
              <td>GST on Material(Input GST)</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[1].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[2].variableCostGST)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[3].variableCostGST)}</td>
            </tr>
            <tr>
              <td>B3 = A2-B2</td>
              <td>Net GST on Sale</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(netGSTOnSale.aggregate[3])}</td>
            </tr>
            <tr>
              <td>B1+B2+B3</td>
              <td>Sub Total Material</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalVariableCost.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Sales Commission </td>
              <td className="text-right">{getNumericFormat(commission.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(commission.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(commission.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>FG Transport</td>
              <td className="text-right">{getNumericFormat(fgTransport.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fgTransport.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td >Credit Card fee</td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.aggregate?.[1] ? productData?.salesProjection?.creditCardFee?.aggregate?.[1] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.aggregate?.[2] ? productData?.salesProjection?.creditCardFee?.aggregate?.[2] : 0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFee?.aggregate?.[3] ? productData?.salesProjection?.creditCardFee?.aggregate?.[3] : 0)} </td>


            </tr>
            <tr>
              <td></td>
              <td>Total Variable Cost</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.aggregate[1]) + parseFloat(creditCardFee.aggregate[1]) + parseFloat(fgTransport.aggregate[1]) + parseFloat(commission.aggregate[1]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.aggregate[2]) + parseFloat(creditCardFee.aggregate[2]) + parseFloat(fgTransport.aggregate[2]) + parseFloat(commission.aggregate[2]))}</td>
              <td className="text-right">{getNumericFormat(parseFloat(totalVariableCost.aggregate[3]) + parseFloat(creditCardFee.aggregate[3]) + parseFloat(fgTransport.aggregate[3]) + parseFloat(commission.aggregate[3]))}</td>
            </tr>


            <tr>
              <td>A3 - (B1 + B2 + B3)</td>
              <td>Gross Profit (Gross Margin)</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Gross Profit as % of sales</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] / totalSalesRevenue.aggregate[1]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] / totalSalesRevenue.aggregate[2]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] / totalSalesRevenue.aggregate[3]) * 100)}%</td>
            </tr>
            <tr>
              <td></td>
              <td>Other Income</td>
              <td className="text-right">{getNumericFormat(otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(otherIncome.aggregate[3])}</td>

            </tr>

            <tr>
              <td></td>
              <td>Interest (Cash)</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostInterest.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>Excluding Interest (Cash)</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostExcludingInterest.aggregate[3])}</td>
            </tr>

            <tr>
              <td></td>
              <td>Dep + Amort (Non Cash)</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[3])}</td>
            </tr>
            <tr>
              <td>C</td>
              <td>Total Fixed Expense</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalFixedExpense.aggregate[3])}</td>
            </tr>
            <tr>
              <td>D</td>
              <td>Profit Before Tax</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[1] - totalFixedExpense.aggregate[1] + otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[2] - totalFixedExpense.aggregate[2] + otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(grossProfit.aggregate[3] - totalFixedExpense.aggregate[3] + otherIncome.aggregate[3])}</td>
            </tr>
            <tr>
              <td>E</td>
              <td>Tax</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[3])}</td>
            </tr>
            <tr>
              <td>D - E = F</td>
              <td>Profit After Tax</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1]) - taxPaid.aggregate[1] + otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2]) - taxPaid.aggregate[2] + otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3]) - taxPaid.aggregate[3] + otherIncome.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td>EBIDTA</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1] - taxPaid.aggregate[1]) + taxPaid.aggregate[1] + fixedCostNonCash.aggregate[1] + fixedCostInterest.aggregate[1] + otherIncome.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2] - taxPaid.aggregate[2]) + taxPaid.aggregate[2] + fixedCostNonCash.aggregate[2] + fixedCostInterest.aggregate[2] + otherIncome.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3] - taxPaid.aggregate[3]) + taxPaid.aggregate[3] + fixedCostNonCash.aggregate[3] + fixedCostInterest.aggregate[3] + otherIncome.aggregate[3])}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default IncomeStatement;
